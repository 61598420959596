import React from 'react';
import Styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import TestimonialCard from './TestimonialCard'

const Section = Styled.div`
  min-height:200px;
  background-color:#fff;
  text-align:center;
  padding-top:30px; 
  width:100%;
  padding-bottom:40px; 
`;
const Header = Styled.span`
font-size:35px;
  font-weight:600; 
  font-family:'Harmonia Sans','Nunito Sans',sans-serif;
`;

const SingelCardWrapper = Styled.div`
padding: 20px;    
outline: none;
background-color:#fff;
`; 

const HealthyTestimonials = ({ data, page}) => {
    // console.log('BuyCardsList', data)
    let finalData = data?.schema_json;
    let content = {
      cards: [
          {
              id: 1,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          },
          {
              id: 2,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          },
          {
              id: 3,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          },
          {
              id: 4,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          },
          {
              id: 5,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          },
          {
              id: 6,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          },
          {
              id: 7,
              description: '<strong>I would have not thought I could do a 5-day fast,</strong> but this FMD was perfect for me. Although there were times I was hungry, I never felt the urge to cheat and had a very successful "fast". I lost over 5 pounds, but more than that I gained so much knowledge. Its easier now to want to eat healthier; even just small tweaks...',
          }
      ],
      ...finalData,
    };
    const cards = content?.cards?.map((card) => {
      return (
        <SingelCardWrapper>
            <TestimonialCard
            key={card.id}
            description={card.description}
            />
        </SingelCardWrapper>
      );
    });

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2,
          infinite: true,
        }
      }, 
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
    autoplaySpeed: 2000, 
        } 
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  return (
    <>
      <Section>
        <Header>The ProLon Fasting Mimicking Diet</Header>
        <br />
        <div>
          <Slider {...settings} style={{ width: '100%' }}>
              {cards}
          </Slider>
        </div>
      </Section>
    </>
  );
}
 
export default HealthyTestimonials;
